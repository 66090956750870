.btn {
  border-radius: 0px;
  padding: 14px 28px;
  border-width: 0px;
  font-size: 18px;
  font-weight: 500;
  &.btn-primary {
    color: $white !important;
    background-color: $main;
    &:hover {
      background-color: black; } }
  &.btn-link {
    color: black;
    text-decoration: none; } }
