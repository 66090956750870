html {
  scroll-behavior: smooth; }
iframe {
  display: none; }
body {
  margin: 0;
  font-family: Interstate;
  .container {
    @media screen and (min-width: 576px) {
      max-width: 540px; }
    @media screen and (min-width: 768px) {
      max-width: 720px; }
    @media screen and (min-width: 992px) {
      max-width: 960px; }
    @media screen and (min-width: 1200px) {
      max-width: 1140px; }
    @media screen and (min-width: 1400px) {
      max-width: calc(100dvw - 20%); } }
  .container-lg {
    @media screen and (min-width: 576px) {
      max-width: 540px; }
    @media screen and (min-width: 768px) {
      max-width: 720px; }
    @media screen and (min-width: 992px) {
      max-width: 960px; }
    @media screen and (min-width: 1200px) {
      max-width: 1140px; }
    @media screen and (min-width: 1400px) {
      max-width: calc(100dvw - 20%); } } }


.stroke-short-solid {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  @media screen and (min-width: $break-mobile-small) {
    padding-bottom: 35px;
    margin-bottom: 35px; }
  &::after {
    width: 24px;
    height: 2px;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: currentColor; } }

.stroke-short-solid.q-stroke-small {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  @media screen and (min-width: $break-mobile-small) {
    padding-bottom: 15px;
    margin-bottom: 35px; } }

a {
  color: $main;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: #000;
    text-decoration: underline;
    text-decoration-thickness: 0.1em; }
  &.light {
    color: $white;
    &:hover {
      color: $white; } }
  &.dark {
    color: $black;
    &:hover {
      color: $darkgrey; } } }
.q-underline {
  text-decoration: underline; }
.q-no-underline {
  text-decoration: none !important; }
#q-page-content {
  margin-bottom: 10px;
  @media screen and (max-width: $break-mobile-small) {
    margin-bottom: 50px; } }

/*HELPER*/
*:focus {
  outline-color: transparent !important;
  outline: none !important;
  box-shadow: none !important; }

.text-transform-none {
  text-transform: none !important; }

.q-mt-large {
  margin-top: 150px;
  @media screen and (max-width: $break-mobile) {
    margin-top: 50px; } }

.q-mb-large {
  margin-bottom: 150px;
  @media screen and (max-width: $break-mobile) {
    margin-bottom: 50px; } }

.q-pt-mobile {
  @media screen and (max-width: $break-mobile-small) {
    padding-top: 50px; } }

.q-text-small {
  font-size: 14px; }

.q-text-big {
  font-size: 33px;
  line-height: 1.24;
  font-family: Averta-Light,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  @media screen and (max-width: $break-mobile-bje) {
    font-size: 21px; } }
.q-link {
  &:hover {
    text-decoration: none; } }

.q-p-mb-0 /*Alle <p></p> ohne margin bottom*/ {
  p {
    margin-bottom: 0; } }

.q-bg-grey {
  background-color: $lightgrey; }

.blue {
  color: $main; }
.light {
  color: $white; }
.black {
  color: $black; }
.q-grey {
  color: $darkgrey; }

.bottom-dots {
  padding-bottom: 0.2em;
  border-bottom: $dots; }

.bottom-dots-light {
  padding-bottom: 0.2em;
  border-bottom: $dots-light; }

.q-bg-transparent {
  background-color: transparent !important; }

.q-small-font {
  font-size: 16px;
  p, a {
    font-size: 16px; } }

.q-small-icon {
  width: 20px; }

.q-medium-icon {
  $size: 60px;
  max-width: $size;
  max-height: $size; }

.q-info-text {
  font-style: italic;
  font-size: 14px; }

.bottom-dots-grey {
  padding-bottom: 0.2em;
  border-bottom: $dots-grey; }

.top-dots {
  padding-top: 0.2em;
  border-top: $dots; }

.q-dropdown-icon {
  display: flex;
  &::before {
    display: none; }
  &::after {
    content: "";
    font-family: icon-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    color: inherit;
    margin-left: 10px;
    @media screen and (max-width: $break-mobile-large) {
      line-height: 1; } }
  &:hover {
    text-decoration: none; } }
.q-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
.q-mobile-relative {
  @media screen and (max-width: $break-mobile-small) {
    position: relative !important; } }

.q-col-hidden {
  @media screen and (max-width: $break-mobile) {
    display: none; } }

.q-img-shadow {
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.30); }

.q-img-hover-effect {
  width: 100%;
  height: auto;
  overflow: hidden;
  img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  &:hover {
    img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1); } } }

.q-cursor-pointer {
  cursor: pointer; }

.q-smooth-scroll {
  scroll-behavior: smooth; }


.chat {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  .message {
    width: 85%;
    border: 1px solid $lightgrey;
    padding: 10px 15px; }
  &.chat-left {
    .message {
        background-color: $main;
        color: $white; } }
  &.chat-right {
    display: flex;
    align-items: flex-end;
    .message {
      background-color: $lightgrey; } } }
/*BJE*/
.q-blue-border-left {
  &::after {
    $width: 20px;
    content: '';
    position: absolute;
    width: $width;
    top: -50px;
    height: 65%;
    left: -$width;
    background-color: rgba(0,159,227,.9);
    @media screen and (max-width: $break-mobile-large) {
      $width: 15px;
      width: $width;
      left: -$width; }
    @media screen and (max-width: $break-mobile-small) {
      display: none; } } }

a.teaser-link {
  border-bottom: none;
  text-decoration: none !important;
  p {
    font-size: 14px !important; } }

.image.small img {
  max-height: 130px;
  width: auto;
  max-width: 100%; }

/**/
.loadingScreen {
  display: flex !important;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  .loader-text {
    margin-left: -50px; } }

/*Z-INDEXES*/
header {
  z-index: 11; }
.q-hero-content, .q-hero-image-small {
  z-index: 10; }
.q-anchorlinks {
  z-index: 9; }

.number {
  color: black;
  min-width: 25px;
  font-weight: 700;
  height: fit-content;
  transform: translateY(calc(7px + 3rem));
  @media screen and (min-width: $break-sm) {
    transform: translateX(-5px) translateY(calc(7px + 3rem)); }
  @media screen and (min-width: $break-md) {
    transform: translateY(calc(9px + 3rem)); }
  @media screen and (min-width: $break-lg) {
    transform: translateY(calc(14px + 3rem)); }
  @media screen and (min-width: $break-xl) {
    transform: translateY(calc(16px + 3rem)); } }

a {
  &:hover {
    text-decoration: none; } }

.line {
  display: flex;
  height: 100%;
  transform: translateX(-10px);
  &::after {
    content: " ";
    width: 2px;
    height: 100%;
    background-color: $main;
    display: block;
    transform: translateX(-10px);
    @media screen and (min-width: $break-md) {
      transform: translateX(0px); } } }

.line {
 &.transparent {
  &::after {
    background-color: transparent; } } }

.dot {
    content: " ";
    width: 18px;
    height: 18px;
    aspect-ratio: 1;
    background-color: $main;
    border-radius: 100px;
    display: block;
    transform: translateX(11px) translateY(calc(9px + 3rem));
    &.black {
      background-color: black; }
    @media screen and (min-width: $break-sm) {
      transform: translateX(0px) translateY(calc(9px + 3rem)); }
    @media screen and (min-width: $break-md) {
      width: 20px;
      height: 20px;
      transform: translateX(11px) translateY(calc(9px + 3rem)); }
    @media screen and (min-width: $break-lg) {
      transform: translateX(11px) translateY(calc(15px + 3rem)); }
    @media screen and (min-width: $break-xl) {
      transform: translateX(11px) translateY(calc(18px + 3rem)); } }

.chapter-preview {
  border: 1px solid $main;
  width: 100%; }

#wrapper {
  section {
    &:first-child {
      .line {
        height: calc(100% - (3rem + 10px));
        &::after {
          transform: translateX(-10px) translateY(calc(20px + 3rem));
          @media screen and (min-width: $break-md) {
            transform: translateY(calc(20px + 3rem)); } } } } } }

.container {
  &.sticky-top {
    top: -1px; } }


#wrapper {
  section {
    scroll-margin-top: 150px; } }


#ansprechpartner {
  background-size: cover;
  background-position: 18dvw;
  background-color: #f5f5f5;
  .bg-effekt {
    @media screen and (min-width: $break-md) {
      background-color: transparent;
      background-image: linear-gradient(127deg, #f5f5f5 0%, #f5f5f5 54%, transparent 54%, transparent 100%); }
    @media screen and (min-width: $break-xl) {
      background-color: transparent;
      background-image: linear-gradient(127deg, #f5f5f5 0%, #f5f5f5 48%, transparent 48%, transparent 100%); } }
  @media screen and (min-width: $break-md) {
    background-image: url("../../../assets/img/kroschke/ansprechpartner.webp");
    background-position: 26dvw; } }
