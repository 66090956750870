.accordion {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  button {
    padding: 14px;
    display: flex;
    align-items: center;
    &::before {
      height: 24px;
      margin-right: 14px;
      content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Union' fill-rule='evenodd' clip-rule='evenodd' d='M13.5 0H10.5V10.5H0V13.5H10.5V24H13.5V13.5H24V10.5H13.5V0Z' fill='%23E4032E'/%3E%3C/svg%3E"); } } }
