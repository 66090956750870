.bg-white {
  &.sticky-top {
    box-shadow: 0px 0px 12px 0px rgba(7,6,5,0.1); } }

.navbar-light {
  .navbar-nav {
    .nav-link {
     color: $main;
     &:hover {
        color: black; } } } }

.navbar-light {
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_89_85)'%3E%3Cpath d='M9 35.5H39' stroke='%23E4032E' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M9 24H39' stroke='%23E4032E' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M9 12.5H39' stroke='%23E4032E' stroke-width='4' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_89_85'%3E%3Crect width='48' height='48' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"); } }

.navbar-light {
  .navbar-toggler {
    border: 1px solid $main;
    border-color: $main;
    border-radius: 0px; } }

.headline-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: $break-lg) {
    width: auto; } }
