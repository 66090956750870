.q-hero-section {
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  background-image: url("../../img/kroschke/hero-background.webp");
  height: 70dvh;
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  .primary-line {
   background-image: linear-gradient(127deg, #fff 0%, #fff 13%, #E4032E 13%, #E4032E 55%, transparent 55%, transparent 100%);
   height: 70dvh;
   width: 100%;
   position: absolute; }
  h2 {
    font-size: 42px;
    font-weight: 800;
    margin-bottom: 1.5rem;
    @media screen and (min-width: $break-sm) {
      font-size: 44px; }
    @media screen and (min-width: $break-md) {
      font-size: 56px; }
    @media screen and (min-width: $break-lg) {
      font-size: 58px; }
    @media screen and (min-width: $break-xl) {
      font-size: 60px; }
    @media screen and (min-width: $break-xxl) {
      font-size: 64px; }
    @media screen and (min-width: $break-xxxl) {
      font-size: 70px; }
    @media screen and (min-width: $break-xxxxl) {
      font-size: 90; } }
  h3 {
    color: white;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 1rem;
    @media screen and (min-width: $break-sm) {
      font-size: 22px; }
    @media screen and (min-width: $break-md) {
      font-size: 23px; }
    @media screen and (min-width: $break-lg) {
      font-size: 24px; }
    @media screen and (min-width: $break-xl) {
      font-size: 24px; }
    @media screen and (min-width: $break-xxl) {
      font-size: 24px; }
    @media screen and (min-width: $break-xxxl) {
      font-size: 28px; } }
  h4 {
    color: white;
    font-weight: 500;
    font-size: 16px;
    @media screen and (min-width: $break-sm) {
      font-size: 16px; }
    @media screen and (min-width: $break-md) {
      font-size: 17px; }
    @media screen and (min-width: $break-lg) {
      font-size: 22px; }
    @media screen and (min-width: $break-xl) {
      font-size: 22px; }
    @media screen and (min-width: $break-xxl) {
      font-size: 22px; }
    @media screen and (min-width: $break-xxxl) {
      font-size: 26px; } } }


/*
  img.parallax
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: auto
    z-index: -1
    transform: scale(1.0)
  */
