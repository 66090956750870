.q-anchorlinks {
  top: 0;
  background-color: $white;
  margin-top: 0;
  z-index: 9999;
  .container-lg {
    @media screen and (min-width: $break-mobile-hd) {
      max-width: 1300px; } }
  .navbar {
    width: 100%;
    left: 0;
    padding-left: 0 !important;
    .nav-item {
      .active {
        color: $black; }
      .nav-link {
        font-size: .875rem;
        line-height: 1.79;
        border-bottom: none;
        font-family: Averta-Bold;
        &.active {
          color: $black !important; }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
          font-size: 13px; } } } }
  @media screen and (max-width: $break-mobile) {
    position: relative !important;
    top: 0; } }

.fixed {
  position: fixed !important;
  top: 75px !important;
  @media screen and (max-width: $break-mobile) {
    position: relative !important;
    top: 0 !important; } }
