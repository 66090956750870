h1 {
  font-family: Interstate; }
h2 {
  font-family: Interstate; }
h3 {
  font-family: Interstate; }
h4 {
  font-family: Interstate; }
h5 {
  font-family: Interstate; }
h6 {
  font-family: Interstate; }
p {
  font-family: Interstate; }
h1 {
  font-size: 66px;
  font-weight: 800; }
h2 {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 1.5rem;
  @media screen and (min-width: $break-sm) {
    font-size: 38px; }
  @media screen and (min-width: $break-md) {
    font-size: 42px; }
  @media screen and (min-width: $break-lg) {
    font-size: 56px; }
  @media screen and (min-width: $break-xl) {
    font-size: 56px; }
  @media screen and (min-width: $break-xxl) {
    font-size: 56px; }
  @media screen and (min-width: $break-xxxl) {
    font-size: 56px; }
  @media screen and (min-width: $break-xxxxl) {
    font-size: 56px; } }
h3 {
  font-size: 32px;
  font-weight: 700;
  @media screen and (min-width: $break-lg) {
    font-size: 44px; }
  @media screen and (min-width: $break-xl) {
    font-size: 48px; } }
h4 {
  font-size: 26px;
  font-weight: 700;
  @media screen and (min-width: $break-xl) {
    font-size: 22px; } }
h5 {
  font-size: 20px;
  font-weight: 500;
  @media screen and (min-width: $break-lg) {
    font-size: 24px; }
  @media screen and (min-width: $break-xl) {
    font-size: 26px; }
  @media screen and (min-width: $break-xxl) {
    font-size: 28px; } }
h6 {
  font-size: 18px;
  font-weight: 700;
  @media screen and (min-width: $break-xl) {
    font-size: 22px; } }
.nav-link {
  font-size: 18px;
  font-weight: 500; }
p {
  font-size: 18px;
  font-weight: 500;
  @media screen and (min-width: $break-xl) {
    font-size: 20px; } }
